import React from 'react'
import ex from '../../images/ex.png'
import './ModalHeader.css'
import canopyLogo from '../../images/canopy-logo.svg'

const ModalHeader = ({ children, onClose }) => {
  return(
    <div className="modalHeader">
      {children}
      {children ? null : <img src={ex} id="modalEx" onClick={() => onClose()} />}
    </div>
  )
}

export default ModalHeader