import React, { useState, useEffect } from 'react'
import useMobileDetect from 'use-mobile-detect-hook'
import './index.css'

const Modal = ({ children, onClose, dimmerStyle, modalStyle, resize, isOpen }) => {

  const [mouseInModal, setMouseInModal] = useState(false)
  const detectMobile = useMobileDetect()
  const [width, setWidth] = useState('')
  const [height, setHeight] = useState('')

  if(modalStyle) modalStyle = {...styles.defaultModal, ...modalStyle}
  else modalStyle = styles.defaultModal

  if(dimmerStyle) dimmerStyle = {...styles.defaultDimmer, ...dimmerStyle}
  else dimmerStyle = styles.defaultDimmer

  useEffect(() => {
      document.addEventListener("keydown", escFunction, false);
      document.addEventListener("mousedown", handleClick, false);
      return function cleanup() {
      document.removeEventListener("keydown", escFunction, false);
      document.removeEventListener("mousedown", handleClick, false);
    }
  }, )

  const escFunction = (event) => {
    if(event.keyCode === 27) {
      onClose()
    }
  }

  const handleClick = (event) => {
    if(mouseInModal === false && !detectMobile.isMobile()) {
     onClose()
    }
  }

  return(
    <div>
      <div style={dimmerStyle} className="modalDimmer" />
      <div style={styles.modalContainer} className="modalContainer">
        <div onMouseOver={() => setMouseInModal(true)} onMouseLeave={() => setMouseInModal(false)} style={modalStyle} className={resize ? "canopyModal resizeModal" : "canopyModal"}>
          {children}
        </div>
      </div>
    </div>
  )
  }


const styles = {

  defaultDimmer: {
    height: '100vh',
    width: '100vw',
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: 'rgba(16, 16, 16, 0.35)',
    
  },

  modalContainer: {
    height: "100vh",
    width: "100vw",
    top: 0,
    left: 0,
    position: 'absolute',
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    justifyItems: "center",
  },

  defaultModal: {
    width: 600,
    backgroundColor: "white",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "solid 1px #dde3ea",
    borderRadius: "4px",
    zIndex: 999
  }
}

export default Modal