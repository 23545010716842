/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"
import leftGroup from "./app-icon-svgs/left-group"
import rightGroup from "./app-icon-svgs/right-group"
import bottomGroup from "./app-icon-svgs/bottom-group"

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="base">
      <Header siteTitle={data.site.siteMetadata.title} location={location} />
      {leftGroup}
      {rightGroup}
      <main>
        {children}
        {bottomGroup}
      </main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
