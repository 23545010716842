import React, { useState, useEffect } from 'react'
import './index.css'

const ContactForm = () => {

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [disabled, setDisabled] = useState (true)
  const [error, setError] = useState (<div className="errorContainer"></div>)
 
  useEffect(() => {
    if(name === '' || email === '' || message === '') setDisabled(true)
    else setDisabled(false)
    setError(<div className="errorContainer"></div>)
  }, [name, email, message])

  const handleClick = () => {
    if(disabled) setError(<div className="errorContainer">Please complete all fields</div>)
    else setError(<div className="errorContainer"></div>)
  }

  return (
    <>
      <form action="/success" name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field" className="contact-form">
        <input type="hidden" name="form-name" value="contact" />
        <h1>Contact Us</h1>
        <div>
          <input type="text" name="name" value={name} onChange={e => setName(e.target.value)} placeholder="Your Name" />  
        </div>
        <div>
          <input type="email" name="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email Address" />
        </div>
        <textarea name="message" value={message} onChange={e => setMessage(e.target.value)} placeholder="How can we help you?"></textarea>
        <div>
          <button type={disabled ? "button" : "submit"} onClick={handleClick} >Send Message</button>
        </div>
        {error}
      </form>
    </>
  )
}

export default ContactForm