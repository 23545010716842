import React from 'react'
import './index.css'

const Footer = () => {
  return (
    <footer className="footer">
      <span>
      © Copyright 2019 Canopy
      </span>
    </footer>
  )
}

export default Footer
