import { Link, navigate } from 'gatsby'
import React, { useState, useEffect } from 'react'
import canopyLogo from '../../images/canopy-logo.svg'
import ContactForm from '../contact'
import Modal from '../modal'
import ModalHeader from '../modal/ModalHeader.js'
import useMobileDetect from 'use-mobile-detect-hook'
import ex from '../../images/ex.png'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import './index.css'

const Header = (props) => {
  let targetElement
  let detectMobile
  const [pathname, setPathname] = useState('')
  const [width, setWidth] = useState('')
  useEffect(() => {
    if(typeof window !== 'undefined') setPathname(props.location.pathname)
    if(typeof window !== 'undefined') setWidth(window.innerWidth)
  })
  if(typeof window !== 'undefined') targetElement = document.querySelector("#contactModal")
  const [modal, setModal] = useState(false)
  useEffect(() => {
    if(modal === true && typeof window !== 'undefined') {
      disableBodyScroll(targetElement)
    }
    if(modal === false && typeof window !== 'undefined') {
      enableBodyScroll(targetElement)
      document.getElementsByClassName("base")[0].style.overflow = 'hidden'
  }}, [modal])

  const toggle = () => {
    document.getElementsByClassName("base")[0].style.overflow = 'visible'
    setTimeout(() => setModal(!modal), 25)
  }
  const renderModal = () => {
    if(typeof window !== 'undefined') {
      return(
      <Modal id="contactModal" resize onClose={toggle} modalStyle={{ width: 568, border: '1px solid #E4E4E4', borderRaius: 3 }}>
        <ModalHeader>
          <div className="modal-header">
            <div id="modal-mobile-header">
              <img src={canopyLogo} id="modal-logo" />
              <h3 id="modal-header-text">Canopy</h3>
            </div>
            <img src={ex} id="modal-ex" onClick={toggle} />
          </div>
        </ModalHeader>
        <ContactForm />
      </Modal>
      )
    }
  }

  return(
    <>
      <header className="header">
        <Link to="/">
          <div>
            <img src={canopyLogo} id="canopy-logo"/>
            <h3>Canopy</h3>
          </div>
        </Link>
        {pathname !== '/contact' ? <h2 onClick={width < 450 ? () => navigate("/contact") : toggle}>Contact</h2> : null}
      </header>
      <div>
        { modal ? 
          renderModal()
          : null
        }
      </div>
    </>
  )
}

export default Header
